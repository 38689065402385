<template>
  <v-dialog v-model="internalIsOpen" max-width="500px">
    <v-card>
      <v-card-title class="headline text-danger">{{
        $t('message_date_emergency.dialog.title')
      }}</v-card-title>
      <v-card-text>
        {{ $t('message_date_emergency.dialog.description') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WarningModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      internalIsOpen: this.isOpen,
    }
  },
  watch: {
    isOpen(newVal) {
      this.internalIsOpen = newVal
    },
    internalIsOpen(newVal) {
      if (!newVal) {
        this.$emit('close')
      }
    },
  },
  methods: {
    closeModal() {
      this.internalIsOpen = false
    },
  },
}
</script>

<style scoped>
.text-danger {
  color: #ff5252;
}
</style>
